<template>
  <div class="home">
    <first-run :help="help" v-on:update:help="help = $event" :language="lang" v-on:update:language="lang = $event"></first-run>
    <v-row>
      <v-col>
        <!-- <div class="text-h6">{{ $t('fields') }}</div> -->
        <v-card :key=render>
          <v-card-title class="text-h5">{{ $t('createField') }}</v-card-title>
          <v-card-text>
            <v-form ref="newField" v-model="newFieldValid">
              <v-combobox v-model="groupName" :items="groups" :label="$t('group')" :rules="groupNameRules"></v-combobox>
              <v-text-field v-model="fieldName" :label="$t('fieldName')" :rules="fieldNameRules"></v-text-field>
              <v-select v-model="fieldType" :items="fieldTypes" :label="$t('fieldType')" :rules="fieldTypeRules"></v-select>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" :disabled="!newFieldValid" @click="createField">{{$t('createFieldAction')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="text-h5 pb-0">{{ $t('fields') }}<v-spacer></v-spacer><v-btn text @click="searchFields" :loading="searching">{{$t('searchFields')}}</v-btn></v-card-title>
          <v-card-text class="py-0 text-center"><v-switch dense flat v-model="highlight" :label="$t('highlight')"></v-switch></v-card-text>
          <v-card-text class="pt-0">
            <v-row v-for="(group, groupName) in allFields" :key="groupName">
              <v-col cols=12 v-if="groupName != 'withoutGroup'">
                <div class="text-h6 font-weight-bold text--white">{{groupName}}</div>
              </v-col>
              <v-col v-for="(type, fieldName) in group" :key="fieldName" cols="6" md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn depressed width="100%" v-if="simpleButtonFields.includes(type)" @contextmenu="show($event, groupName, fieldName)" @click="replaceText(groupName, fieldName, type)"><v-icon left>{{fieldTypeIcons[type]}}</v-icon>{{ trimFieldName(fieldName) }}</v-btn>
                      <v-menu v-else >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn depressed width="100%" @contextmenu="show($event, groupName, fieldName)" v-bind="attrs" v-on="on"><v-icon left>{{fieldTypeIcons[type]}}</v-icon>{{trimFieldName(fieldName)}}</v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in getExtraFieldItems(type, fieldName)"
                            :key="index"
                            @click="replaceText(groupName, item.value, type)"
                          >
                            <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                  <span>{{ fieldName }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-menu
              v-model="showMenu"
              :position-x="x"
              :position-y="y"
              absolute
              offset-y
            >
              <v-list>
                <v-list-item @click="deleteField()">
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="text-center">
        <v-btn color="error" text  @click="dialogReset = true">{{ $t('resetFields') }}</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogReset">
      <v-card>
        <v-card-title>
          <div class="text-wrap">{{ $t('resetTitle') }}</div>
        </v-card-title>
        <v-card-text>{{ $t('resetText') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogReset = false"> {{ $t('cancel') }}</v-btn>
          <v-btn color="red darken-1" text @click="dialogReset = false; allFields={'withoutGroup': {}}; groups=[]">{{ $t('reset') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import FirstRun from './FirstRun.vue'

export default {
  name: 'Home',
  components: {
    FirstRun
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    help: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    render: 0,
    trimThresh: 12,
    trimLength: 10,
    lang: 'en',
    firstRun: true,
    groups: [],
    fields: [],
    fieldName: null,
    groupName: null,
    fieldType: null,
    highlight: false,
    allFields: {
      'withoutGroup': {}
    },
    fieldTypeIcons: {
      'text': 'mdi-format-text',
      'date': 'mdi-calendar',
      'number': 'mdi-numeric',
      'code': 'mdi-code-tags',
      'currency': 'mdi-cash',
      'dropdown': 'mdi-arrow-down-drop-circle',
      'option': 'mdi-check-box-outline',
    },
    simpleButtonFields: ['text', 'dropdown', 'date'],
    showMenu: false,
    x: 0,
    y: 0,
    delGroupName: null,
    delFieldName: null,
    nItemsFound: 0,
    itemsFound: null,
    searching: false,
    newFieldValid: false,
    dialogReset: false,
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: ''
  }),
  computed: {
    fieldTypes: function () {
      return [
        {value: 'text', text: this.$t('text')},
        {value: 'date', text: this.$t('date')},
        {value: 'number', text: this.$t('number')},
        {value: 'code', text: this.$t('code')},
        {value: 'currency', text: this.$t('currency')},
        {value: 'dropdown', text: this.$t('dropdown')},
        {value: 'option', text: this.$t('option')},
      ]
    },
    fieldNameRules: function () {
      return this.$t('fieldNameRules')
    },
    fieldTypeRules: function () {
      return this.$t('fieldTypeRules')
    },
    groupNameRules: function () {
      return this.$t('groupNameRules')
    },
  },
  methods: {
    show (e, groupName, fieldName) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.delGroupName = groupName
      this.delFieldName = fieldName
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    createField() {
      var obj = {...this.allFields}
      if(this.fieldType == null) {
        return
      }
      if(this.fieldName == null) {
        return
      }
      var groupName = this.groupName
      if(this.groupName == null){
        groupName = 'withoutGroup'
      }
      if(obj[groupName] == null){
        obj[groupName] = {}
        this.groups.push(groupName)
      }
      if(obj[groupName][this.fieldName] == null){
        obj[groupName][this.fieldName] = this.fieldType
      }
      this.allFields = obj
      this.$refs.newField.reset()
    },
    deleteField() {
      var obj = {...this.allFields}
      delete obj[this.delGroupName][this.delFieldName]
      if(Object.keys(obj[this.delGroupName]).length == 0){
        delete obj[this.delGroupName]
        this.groups.splice(this.groups.indexOf(this.delGroupName), 1)
      }
      this.allFields = obj
      this.delGroupName = null
      this.delFieldName = null
    },
    replaceText(group, fieldName, type) {
      var newText = null
      var preppendText = ''
      if(group != 'withoutGroup'){
        preppendText = group+'.'
        if(type == 'option'){
          preppendText = fieldName.charAt(0) + group+'.'
        }
      } else{
         if(type == 'option'){
          preppendText = fieldName.charAt(0)
        }
      }
      if(['text', 'number', 'code', 'currency'].includes(type)){
        newText = '{' + preppendText + `${fieldName}}`
      }
      else if(type == 'date'){
        newText = '{' + preppendText + `${fieldName}` + '_date}'
      }
      else if(type == 'dropdown'){
        newText = '{' + preppendText + `${fieldName}` + '_drop}'
      }
      else if(type == 'option'){
        newText = '{' + preppendText + `${fieldName.substring(1)}}`
      }
      
      window.Word.run(async context => {
        var doc = context.document;
        var originalRange = doc.getSelection();
        originalRange.insertText(newText, "Replace")
        originalRange.select('End')
        if(this.highlight){
          // var newRange = originalRange.getNextTextRange(['}'])
          originalRange.font.set({
            highlightColor: "#00FF00"
          })
        }
      });
    },
    getExtraFieldItems(type, fieldName){
      var items = []
      if(type == 'number'){
        items.push({text: 'numbers', value: fieldName})
        items.push({text: 'letters', value: fieldName+'_letters'})
      }
      else if(type == 'code'){
        items.push({text: 'code', value: fieldName})
        items.push({text: 'letters', value: fieldName+'_code'})
      }
      else if(type == 'currency'){
        items.push({text: 'amount', value: fieldName})
        items.push({text: 'units', value: fieldName+'_currencyUnits'})
        items.push({text: 'decimals', value: fieldName+'_currencyDecimals'})
      }
      else if(type == 'option'){
        items.push({text: 'optionStart', value: '#'+fieldName})
        items.push({text: 'optionEnd', value: '/'+fieldName})
      }
      return items
    },
    searchFields() {
      let vm = this
      vm.searching = true
        window.Word.run(function (context) {

        // Queue a command to search the document and ignore punctuation.
        var searchResults = context.document.body.search('[{]*[}]', {matchWildcards: true});

        // Queue a command to load the search results and get the font property values.
        context.load(searchResults, 'text');

        // Synchronize the document state by executing the queued commands,
        // and return a promise to indicate task completion.
        return context.sync().then(function () {
            console.log('Found count: ' + searchResults.items.length);
            vm.nItemsFound = searchResults.items.length
            vm.itemsFound = searchResults.items
            // Queue a set of commands to change the font for each found item.
            // for (var i = 0; i < searchResults.items.length; i++) {
            //     searchResults.items[i].font.color = 'purple';
            //     searchResults.items[i].font.highlightColor = '#FFFF00'; //Yellow
            //     searchResults.items[i].font.bold = true;
            // }

            // Synchronize the document state by executing the queued commands,
            // and return a promise to indicate task completion.
            vm.searching = false
            return context.sync();
        });
      })
    },
    updateAllFields(items){
      // this.searching = true
      var allFields = {...this.allFields}
      for (var i = 0; i < items.length; i++) {
        var text = items[i].text.substring(1, items[i].text.length-1)
       
        var suffix = ''
        var prefix = ''
        var groupName = ''
        var indexPrefix = text.indexOf(".")
        var indexSuffix = text.lastIndexOf("_")
        if(indexSuffix !== -1){
          suffix = text.substring(indexSuffix+1)
        }
        // get field type and group if it has one
        var type = this.suffix2Type(suffix)
        if(indexPrefix !== -1){
          prefix = text.substring(0, indexPrefix)
          groupName = prefix
          // if(prefix.charAt(0) == '#' || prefix.charAt(0) == '/'){
          //   type = 'option'
          //   groupName = prefix.substring(1)
          // }
        }
        if(text.charAt(0) == '#' || text.charAt(0) == '/'){
          type = 'option'
          if(groupName !== '' && indexPrefix !== -1){
            groupName = prefix.substring(1)
          }
          else if(groupName == '' && indexPrefix === -1){
            text = text.substring(1)
          }
        }

        var fieldName
        // group and type
        if(indexPrefix != -1 && indexSuffix != -1){
          fieldName = text.substring(indexPrefix+1, indexSuffix)
        // group
        } else if(indexPrefix != -1 && indexSuffix == -1){
          fieldName = text.substring(indexPrefix+1)
        // type
        } else if(indexPrefix == -1 && indexSuffix != -1) {
          fieldName = text.substring(0, indexSuffix)
        }
        else {
          fieldName = text
        }

        if(groupName == ''){
          groupName = 'withoutGroup'
        }

        if(allFields[groupName] == null){
          allFields[groupName] = {}
          this.groups.push(groupName)
        }
        // prioritize by type all > text
        if(type == 'text' && allFields[groupName] != null && allFields[groupName][fieldName] == null){
          allFields[groupName][fieldName] = type
        }
        if(type != 'text'){
          allFields[groupName][fieldName] = type
        }
      }
      this.allFields = allFields
      // this.searching = false
      this.setSnackbar(this.$t('searchSuccess'), 'success')
    },
    suffix2Type(suffix){
      switch(suffix){
        case 'date':
          return 'date'
        case 'drop':
          return 'dropdown'
        case 'letters':
          return 'number'
        case 'code':
          return 'code'
        case 'currencyUnits':
          return 'currency'
        case 'currencyDecimals':
          return 'currency'
        default:
          return 'text'
      }
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },
    changeLang(){
      this.$emit('update:language', this.lang)
    },
    trimFieldName(fieldName){
      if(fieldName.length > this.trimThresh){
        return fieldName.slice(0, this.trimLength) + '...'
      }
      else return fieldName
    }
  },
  mounted () {
    this.lang = this.language
    if (localStorage.getItem('allFields')) {
      // this.allFields = JSON.parse(localStorage.allFields)
      this.allFields = JSON.parse(localStorage.getItem('allFields'))
    }
  },
  watch: {
    help: function() {
      this.$emit('update:help', this.help)
    },
    lang: function() {
      this.changeLang()
    },
    language: function() {
      this.render++
      this.lang = this.language
    },
    allFields: {
      deep: true,
      // eslint-disable-next-line
      handler: function (val, oldVal) {
        localStorage.setItem('allFields', JSON.stringify(this.allFields))
      }
      // localStorage.allFields = JSON.stringify(this.allFields)
    },
    itemsFound: {
      deep: true,
       // eslint-disable-next-line
      handler: function (val, oldVal) {
        this.updateAllFields(val)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.card-title {
  word-break: normal; /* maybe !important  */
}
</style>
