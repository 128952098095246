<template>
  <div id="firstRun">
    <v-dialog v-model="firstRun" fullscreen transition="dialog-bottom-transition">
      <v-card height="100%">
        <v-toolbar dark>
          <v-row align="center" justify="end" no-gutters>
            <v-col width="50%">
              <v-select
                v-model="lang"
                :items="languages"
                item-text="title"
                item-value="language"
                hide-details
                single-line
                dense
                solo
                flat
              ></v-select>
            </v-col>
          </v-row>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="firstRun = false" >
              {{ $t('skip') }}
              <v-icon right>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-carousel v-model="page" show-arrows-on-hover continuous :height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
          <!-- Intro -->
          <v-carousel-item>
            <v-card flat class="justify-center text-center">
              <v-img
                min-height="30vh"
                max-height="50vh"
                src="@/assets/background2.jpeg"
                gradient="to top right, rgba(20,20,20,.33), rgba(20,30,20,.10)">
                <v-container fill-height>
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <v-col
                      class="text-center"
                      cols="12"
                    >
                      <h1 class="font-weight-black headline">
                        Contractflows
                      </h1>
                      <h4 class="subheading" >
                        {{ $t('slogan') }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
              <v-card-title class="justify-center text-center">
                <div class="text-wrap">{{ $t('firstRun.intro.title') }}</div>
              </v-card-title>
              <v-card-text>{{ $t('firstRun.intro.text1') }}</v-card-text>
            </v-card>
          </v-carousel-item>
          <!-- Create Field -->
          <v-carousel-item>
            <v-card flat class="justify-center text-center">
              <v-img
                max-height="60vh"
                contain
                src="@/assets/create2.gif"
              ></v-img>
              <v-card-title class="justify-center text-center">
                <div class="text-wrap">{{ $t('firstRun.create.title') }}</div>
              </v-card-title>
              <v-card-text>
                <v-dialog
                  transition="dialog-bottom-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      bottom
                    >
                      <v-icon>mdi-format-list-numbered</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <!-- <v-toolbar flat dark>{{ $t('firstRun.create.title') }}</v-toolbar> -->
                      <v-card-text class="ma-0 pa-1 text-left">
                        <ol>
                          <li v-for="item in $t('firstRun.create.text1')" :key="item">{{item}}</li>
                        </ol>
                      </v-card-text>
                      <v-card-text class="ma-0 pa-1 text-left">{{ $t('firstRun.create.text2') }}</v-card-text>
                      <v-card-text class="ma-0 pa-1 text-left">{{ $t('firstRun.create.text3') }}</v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          text
                          @click="dialog.value = false"
                        >{{ $t('close') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-card-text>
              
              
            </v-card>
          </v-carousel-item>
          <!-- Add field -->
          <v-carousel-item>
            <v-card flat class="justify-center text-center">
              <v-img
                max-height="60vh"
                contain
                src="@/assets/insert2.gif"
              ></v-img>
              <v-card-title class="justify-center text-center">
                <div class="text-wrap">{{ $t('firstRun.insert.title') }}</div>
              </v-card-title>
              <v-card-text>
                <v-dialog
                  transition="dialog-bottom-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      bottom
                    >
                      <v-icon>mdi-format-list-numbered</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <!-- <v-toolbar flat dark>{{ $t('firstRun.create.title') }}</v-toolbar> -->
                      <v-card-text class="py-1 text-left">
                        <ol>
                          <li v-for="item in $t('firstRun.insert.text1')" :key="item">{{item}}</li>
                        </ol>
                      </v-card-text>
                      <v-card-text class="py-1 text-left">{{ $t('firstRun.insert.text2') }} (<a href="https://contractflows.com/docs" target="_blank" >contractflows.com/docs</a>).</v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          text
                          @click="dialog.value = false"
                        >{{ $t('close') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-card-text>
              
            </v-card>
          </v-carousel-item>
          <!-- reutilize -->
          <v-carousel-item>
            <v-card flat class="justify-center text-center">
              <v-img
                max-height="60vh"
                contain
                src="@/assets/search2.gif"
              ></v-img>
              <v-card-title class="justify-center text-center">
                <div class="text-wrap">{{ $t('firstRun.reutilize.title') }}</div>
              </v-card-title>
              <v-card-text>
                <v-dialog
                  transition="dialog-bottom-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      bottom
                    >
                      <v-icon>mdi-format-list-numbered</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <!-- <v-toolbar flat dark>{{ $t('firstRun.create.title') }}</v-toolbar> -->
                      <v-card-text class="py-1 text-left">
                        <ol>
                          <li v-for="item in $t('firstRun.reutilize.text1')" :key="item">{{item}}</li>
                        </ol>
                      </v-card-text>
                      
                      <v-card-actions class="justify-end">
                        <v-btn
                          text
                          @click="dialog.value = false"
                        >{{ $t('close') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <v-btn class="mx-1" @click="firstRun = false" color="primary">{{ $t('getStarted') }}</v-btn>
              </v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
  
  
</template>

<script>
export default {
  name: 'FirstRun',
  props: {
    language: {
      type: String,
      required: true,
    },
    help: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    firstRun: false,
    page: 0,
    languages: [
      { flag: 'us', language: 'en', title: 'English' },
      { flag: 'es', language: 'es', title: 'Español' }
    ],
    lang: 'en'
  }),
  methods: {
    changeLang(){
      this.$emit('update:language', this.lang)
    },
    resetHelp(){
      this.$emit('update:help', false)
    }
  },
  mounted () {
    // this.lang = this.language
    // delete localStorage.firstRun
    if(!localStorage.firstRun) {
      this.firstRun = true
      localStorage.firstRun = true
    }
  },
  
  watch: {
    lang: function() {
      this.changeLang()
    },
    language: function() {
      this.lang = this.language
    },
    help: function() {
      this.firstRun = this.help
    },
    firstRun: function() {
      if(this.firstRun === false){
        this.help = false
        this.page = 0
        this.$emit('update:help', false)
      }
      else{
        this.page = 0
      }
    },
  }
}
</script>

<style>
body {
  font-size: 100%;
}
.textFirst {
  font-size: 10px;
}
.v-card__text, .v-card__title div {
  word-break: normal; /* maybe !important  */
}
</style>
