import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true
    // themes: {
    //   light: {
    //     primary: '#ee44aa',
    //     secondary: '#424242',
    //     accent: '#82B1FF',
    //     error: '#FF5252',
    //     info: '#2196F3',
    //     success: '#4CAF50',
    //     warning: '#FFC107'
    //   },
    // },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  breakpoint: {
    thresholds: {
      xs: 1500,
      sm: 1505,
      md: 1510,
      lg: 1515,
    },
  },
  css: {
    loaderOptions: {
      sass: {
        data: `@import "@/sass/main.scss"`,
      },
    },
  },
});
