<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer app>
        <!-- -->
      </v-navigation-drawer>

      <v-app-bar app>
        <v-row align="center" justify="end" no-gutters>
          <v-col width="50%">
            <v-select
              class="px-3"
              v-model="language"
              :items="languages"
              item-text="title"
              item-value="language"
              hide-details
              single-line
              dense
              solo
              flat
            ></v-select>
          </v-col>
        </v-row>
        <v-toolbar-title class="font-weight-black headline">         
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-row align="center" justify="end" no-gutters>
            <v-btn icon @click="help = true">
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
        </v-row>
      </v-app-bar>

      <!-- Sizes your content based upon application components -->
      <v-main>

        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <home :help="help"  v-on:update:help="help = $event" :language=language v-on:update:language="language = $event"></home>
          <!-- <v-sheet class="pa-0"> -->
            <!-- <div id="actions">
              <v-btn @click="onReplace">Replace Text</v-btn>
            </div> -->
          <!-- </v-sheet> -->
          <!-- If using vue-router -->
          <!-- <router-view></router-view> -->
        </v-container>
      </v-main>
      <v-footer app>
        <!-- -->
      </v-footer>
    </v-app>




  </div>
</template>
<script>
import i18n from '@/plugins/i18n'
import Home from './components/Home';

export default {
  name: 'App',
  components: {
    Home
  },
  data: () => ({
    help: false,
    languages: [
      { flag: 'us', language: 'en', title: 'English' },
      { flag: 'es', language: 'es', title: 'Español' }
    ],
    language: 'en'
  }),
  methods: {
    changeLocale (locale) {
      i18n.locale = locale
    },
    getBrowserLocale (options = {}) {
      const defaultOptions = { countryCodeOnly: false }
      const opt = { ...defaultOptions, ...options }
      const navigatorLocale =
        navigator.languages !== undefined
          ? navigator.languages[0]
          : navigator.language
      if (!navigatorLocale) {
        return undefined
      }
      const trimmedLocale = opt.countryCodeOnly
        ? navigatorLocale.trim().split(/-|_/)[0]
        : navigatorLocale.trim()
      return trimmedLocale
    },
    onReplace() {
      window.Word.run(async context => {
        var doc = context.document;
        var originalRange = doc.getSelection();
        originalRange.insertText("many", "Replace")
      });
    }
  },
  mounted () {
    if (localStorage.language) {
      this.language = localStorage.language
    } else {
      const browserLocale = this.getBrowserLocale({ countryCodeOnly: true })
      if (['en', 'es'].includes(browserLocale)) {
        this.language = browserLocale
      } else {
        this.language = 'en'
      }
    }
  },
  watch: {
    language: function () {
      localStorage.language = this.language
      this.$i18n.locale = this.language
      // this.$store.dispatch('set_language', this.language)
    }
  }
};
</script>

<style>
  .content-header {
    background: #2a8dd4;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    overflow: hidden;
  }

  .content-main {
    background: #fff;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .padding {
    padding: 15px;
  }
  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }
  body {
    overflow: hidden; /* Hide scrollbars */
  }
</style>