import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    searchSuccess: 'Found Fields added',
    slogan: 'The smart way to draft contracts',
    getStarted: 'Get Started',
    highlight: 'Highlight',
    close: 'Close',
    skip: 'Skip',
    fields: 'Fields',
    createField: 'Create a new Field',
    createFieldAction: 'Create Field',
    fieldType: 'Field Type',
    text: 'Text',
    date: 'Date',
    number: 'Number',
    code: 'Code',
    currency: 'Currency',
    dropdown: 'Dropdown',
    option: 'Option',
    group: 'Group',
    fieldName: 'Field Name',
    numbers: 'Numbers',
    letters: 'Letters',
    amount: 'Amount',
    units: 'Units in Letters',
    decimals: 'Cents in Letters',
    optionStart: 'Start',
    optionEnd: 'End',
    delete: 'Delete',
    searchFields: 'Search Fields',
    resetFields: 'Reset Fields',
    reset: 'Reset',
    resetTitle: 'Do you want to reset the fields?',
    resetText: 'The fields will be removed from addin. If you change your mind, you can sarch for fields any time.',
    cancel: 'Cancel',
    // rules
    fieldNameRules: [
      v => !!v || 'Field Name is required',
      v => /^[a-zA-Z\d]+$/.test(v) || 'A-Z a-z 1-9, no whitespace'
    ],
    fieldTypeRules: [
      v => !!v || 'Field Type is required',
    ],
    groupNameRules: [
      v => /^[a-zA-Z\d]+$/.test(v) || 'A-Z a-z 1-9, no whitespace',
    ],
    firstRun: {
      intro: {
        img: '',
        title: 'Create your own templates for Contractflows',
        text1: 'Contractflows reads word documents for special syntax and identifies the variables you define. These variables are to shown to you in the autogenerated smart forms when a document is uploaded.'
      },
      create: {
        img: '',
        title: 'Create a new Field',
        text1: ['Write a new group or select one available in the list (Optional).',
                'Write a new field name.',
                'Select a field type from the ones available in the list.',
                'Click on create field button.'],
        text2: 'The new field will appear in the "Fields" section below',
        text3: 'To delete a field from the "Fields" section, right click on it and click on delete prompt.'
      },
      insert: {
        img: '',
        title: 'Insert a variable into your document',
        text1: ['Select the text on your document you want to replace with the variable.',
                'Click on a field button inside the "Fields" section. You can toggle to highlight newly inserted variables on your document by activating the switch next to the "Fields" section title.',
                ],
        text2: 'Some field types have various variables associated with them, showing a menu with different options when clicked, please refer to our documentation page for more details on the types of fields ',
      },
      reutilize: {
        img: '',
        title: 'Reutilize variables',
        text1: ['Open the document you want to use the variables from.',
                'Scroll down to the bottom of the add-in, click on the red "RESET FIELDS" button, and confirm reset (Recommended)',
                'Click on "Search Fields" button on the top right corner of the "Fields" section.',
                'Close the document, open the one you want to work on, and open the Contractflows add-in.'
                ],
      }
    }
  },
  es: {
    searchSuccess: 'Campos encontrados agregados',
    slogan: 'La manera inteligente de crear contratos',
    getStarted: '¡Empecemos!',
    highlight: 'Resaltar',
    close: 'Cerrar',
    skip: 'Saltar',
    fields: 'Campos',
    createField: 'Crear un nuevo Campo',
    createFieldAction: 'Crear',
    fieldType: 'Tipo de Campo',
    text: 'Texto',
    date: 'Fecha',
    number: 'Número',
    code: 'Código',
    currency: 'Moneda',
    dropdown: 'Desplegable',
    option: 'Opcional',
    group: 'Grupo',
    fieldName: 'Nombre del Campo',
    numbers: 'Números',
    letters: 'Letras',
    amount: 'Monto',
    units: 'Unidades en Letras',
    decimals: 'Centavos en Letras',
    optionStart: 'Inicio',
    optionEnd: 'Final',
    delete: 'Borrar',
    searchFields: 'Buscar Campos',
    resetFields: 'Reiniciar Campos',
    reset: 'Reiniciar',
    resetTitle: '¿Quieres reiniciar los campos?',
    resetText: 'Los campos serán removidos en el addin. Si cambias de opinión, puedes buscar campos en el documento cuando quieras.',
    cancel: 'Cancelar',
    // rules
    fieldNameRules: [
      v => !!v || 'El nombre del campo es requerido',
      v => /^[a-zA-Z\d]+$/.test(v) || 'A-Z a-z 1-9, sin espacios'
    ],
    fieldTypeRules: [
      v => !!v || 'El tipo de campo es requerido',
    ],
    groupNameRules: [
      v => /^[a-zA-Z\d]+$/.test(v) || 'A-Z a-z 1-9, sin espacios',
    ],
    firstRun: {
      intro: {
        img: '',
        title: 'Crea tus plantillas personalizadas para usar en Contractflows',
        text1: 'Contractflows busca en el documento por una sintaxis especial para identificar las variables que definas. Estas variables son mostradas en los formularios inteligentes autogenerados cuando se sube un documento a la plataforma.'
      },
      create: {
        img: '',
        title: 'Crea un nuevo campo',
        text1: ['Escribe un nuevo grupo o selecciona uno de la lista (Opcional).',
                'Escribe un nuevo nombre para el campo.',
                'Selecciona el tipo de campo de los disponibles en la lista.',
                'Haz click en el botón de crear.'],
        text2: 'El nuevo campo aparecerá en la sección de "Campos" en la parte de abajo.',
        text3: 'Para borrar un campo de la sección de "Campos", haz click derecho sobre el y vuelve hacer click en borrar.'
      },
      insert: {
        img: '',
        title: 'Inserta un campo dentro del documento',
        text1: ['Selecciona el texto dentro del documento que quieras reemplazar por el texto del campo.',
                'Haz click en el botón del campo que quieras insertar en la sección de "Campos". Puedes resaltar los nuevos campos insertados activando el switch en la parte superior de la sección de "Campos".',
                ],
        text2: 'Algunos tipos de campos son compuestos por más de un campo, se muestra un menú con los diferentes campos cuando se hace click, puedes referirte a nuestra página de documentación para más detalles sobre los tipos de campos ',
      },
      reutilize: {
        img: '',
        title: 'Reutiliza variables',
        text1: ['Abre el documento de la plantilla que contenga las variables que quieres reutilizar.',
                'Navega hacia la parte mas baja del add-in, haz click en el botón rojo "REINICIAR CAMPOS" y confirma el reset (Recomendado)',
                'Haz click en el botón "Buscar Campos" en la esquina superior derecha de la sección de "Campos".',
                'Cierra el documento, abre el documento en el cual quieras trabajar y abre el add-in de Contractflows.'
                ],
      }
    }
  }
}
// eslint-disable-next-line
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages // set locale messages
})

export default i18n
